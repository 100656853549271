// https://www.npmjs.com/package/react-vertical-timeline-component

import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTree,
  faDog,
  faCat,
  faBed,
  faSun,
  faHammer,
  faCouch,
  faHome,
  faHouseDamage,
  faLock,
  faDoorOpen,
  faCertificate,
} from '@fortawesome/free-solid-svg-icons';
import { faAffiliatetheme } from '@fortawesome/free-brands-svg-icons';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Layout from '../components/layout';
import '../styles/timeline.scss';

const FuturePlans = ({ data }) => {
  var pageTitle = 'Future Plans';

  return (
    <Layout
      slug="future-plans"
      title={pageTitle}
      description="Learn more about the various ways we are updating and improving our facility to ensure your pet has the best stay possible!"
    >
      <h1>{pageTitle}</h1>

      <p>
        We are constantly updating, improving and adding to our facility with
        new, innovative and exciting ideas in an effort to ensure that your pets
        have the best experience possible! This page will help you keep up to
        date with our newest additions and upcoming projects.
      </p>

      <h2 className="h1 text-center mb-8">Coming Soon</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          id="countryside-walks"
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="Spring 2023"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHammer} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">New Bathing Room</h3>
          <p className="!mb-4">
            This spring we will be completing our new bathing room! This will be
            a great asset to our grooming team and we can't wait to share the
            completed project with you!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="Summer 2023"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faDog} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">Tire Playgrounds</h3>
          <p className="!mb-4">
            We are currently working to build a dog playground for each of our
            play yards! We can't wait to share this new and exciting feature
            with your canine friends!
          </p>
          <GatsbyImage
            image={getImage(data.image2)}
            alt="tire playground"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="Fall 2023"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHammer} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">Luxury Suite #2</h3>
          <p>
            Due to the popularity of our original Luxury Suite we have decided
            to add another one! This suite will be themed after a log cabin and
            we are so excited to share it with you!
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>

      <h2 className="h1 text-center my-8">Previous Additions</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="Winter 2023"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faBed} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Additional Raised Bedding Options
          </h3>
          <p className="!mb-4">
            While we have some raised beds available at the kennel we will be
            building more soon!
          </p>
          <GatsbyImage
            image={getImage(data.image6)}
            alt="raised pet bed"
            className="rounded-xl"
            style={{ maxWidth: '500px' }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="Fall 2021"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faCat} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Additional Outdoor Cat Playgrounds
          </h3>
          <p>
            Due to the popularity of our original cat playground we built more
            and added one for each cat room to ensure every feline has their own
            private play area!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          id="countryside-walks"
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="Spring 2021"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faTree} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">Add ons</h3>
          <p className="!mb-4">
            As a new service we will be offering extra add ons such as sniffy walks, agility sessions, hide n' seek, stuffed kongs and more to our boarding
            dogs. These will be private one on one sessions designed to
            provide extra mental and physical stimulation for the dogs in our
            care. These will be offered in addition to their usual
            activities at the kennel.
          </p>
          <GatsbyImage
            image={getImage(data.image19)}
            alt="dog walking"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="May 2021"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faLock} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            New Security System
          </h3>
          <p>
            We are updating to a new, state of the art alarm system includes
            everything from temperature sensors to video cameras to an alarm
            system. With this new system we can moniter all vehicles entering
            our property, the dogs, temperature, Co2, smoke levels and more from
            both remote locations and on property. In addition the smoke/heat
            alarms are also monitered by the security company for additional
            safety.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="January 2021"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon
              icon={faAffiliatetheme}
              size="2x"
              style={{ width: 24 }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Private Kennel Free Grooming/At Home Nail Trims
          </h3>
          <p>
            Two new service we added are private, kennel free grooming and at
            home nail trims. Both a great option for pets who are not used to,
            or comfortable with the grooming procedure or other dogs.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="December 2020"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faCat} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            A Outdoor Cat Playground
          </h3>
          <p className="!mb-4">
            We recently built large cat playgrounds designed to be outdoors. So
            far the cats in our care have been loving this new feature ane we
            plan to add more in the future!
          </p>
          <GatsbyImage
            image={getImage(data.image3)}
            alt="cat tree"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="June 2020"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faSun} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Expanded Play Yards
          </h3>
          <p className="!mb-4">
            During the summer of 2020 we spent a lot of time expanding and
            updating our play yards. We now have over 30,000 sq. ft. of play
            yards.
          </p>
          <GatsbyImage
            image={getImage(data.image4)}
            alt="yard construction"
            className="rounded-xl"
            style={{ maxWidth: '500px' }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="May 2020"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHammer} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Repaired all buildings from Hail damage
          </h3>
          <p className="!mb-4">
            The month of May was spent replacing roofs, siding and doors from
            due to damage from a hail storm late in the previous summer
          </p>
          <GatsbyImage
            image={getImage(data.image7)}
            alt="building contruction"
            className="rounded-xl"
            style={{ maxWidth: '500px' }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="December 2019"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon
              icon={faCertificate}
              size="2x"
              style={{ width: 24 }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Certified Veterinary Technical Assistant
          </h3>
          <p>
            Tiana graduated from Olds College as a certified veterinary
            assistant.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="November 2019"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon
              icon={faCertificate}
              size="2x"
              style={{ width: 24 }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Pet First Aid Certified
          </h3>
          <p className="!mb-4">
            This November Very Important Pets sent Laura, Tiana and Carla, all
            of our full time staff, to take their pet first aid course.
          </p>
          <GatsbyImage
            image={getImage(data.image18)}
            alt="certificate"
            className="rounded-xl"
            style={{ maxWidth: '500px' }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="June 2019"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHammer} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Redid Outdoor Roofs
          </h3>
          <p>
            To ensure the safety of your pets we replaced all of the wire roofs
            on the outdoor portion of our private kennels.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="January 2019"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faCouch} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">Pawsh Suite</h3>
          <p>
            Due to the increasing demand for our luxury suite we decided to
            build the Pawsh Suite to further pamper your pets!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="September 2019"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faCouch} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">Luxury Suite #1</h3>
          <p>
            We built our first luxury suite in the fall of 2019 creating more
            deluxe options for our canine visitors.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="June 2018"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faSun} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Expanded Play Yards
          </h3>
          <p>
            Due to the newest kennel addition we felt it was the perfect time to
            expand our play yards!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="July 2017"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHome} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Built our Newest Kennel
          </h3>
          <p className="!mb-4">
            As a replacement for the kennel we removed we built a new one which
            included a new grooming area, cat room, family suite and a small dog
            daycare area!
          </p>
          <GatsbyImage
            image={getImage(data.image9)}
            alt="building contruction"
            className="rounded-xl"
            style={{ maxWidth: '500px' }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="February 2018"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon
              icon={faCertificate}
              size="2x"
              style={{ width: 24 }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Certified Cat Groomer
          </h3>
          <p>
            Very Important Pets sent Tiana to school where she became an
            internationally certified cat groomer.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="May 2017"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faDog} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Added Daycare as a New Service
          </h3>
          <p>
            We added this new service to ensure your dogs have the best time
            with us!
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="April 2017"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon
              icon={faHouseDamage}
              size="2x"
              style={{ width: 24 }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Removed our Original Kennel
          </h3>
          <p className="!mb-4">
            While our first kennel was a great start for our business it was
            time to update!
          </p>
          <GatsbyImage
            image={getImage(data.image10)}
            alt="building removal"
            className="rounded-xl"
            style={{ maxWidth: '500px' }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="August 2016"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faSun} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Built New Outdoor Yards
          </h3>
          <p className="!mb-4">
            With the new kennel we decided it was the perfect time to build
            another large play yard. This yard has a wire underlay and is dug
            into the ground to prevent digging out.
          </p>
          <GatsbyImage
            image={getImage(data.image16)}
            alt="yard contruction"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="June 2016"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHome} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Built our 3rd Kennel
          </h3>
          <p className="!mb-4">
            We decided it was time to retire our original building so we built a
            new one to replace it!
          </p>
          <GatsbyImage
            image={getImage(data.image11)}
            alt="building construction"
            className="rounded-xl"
            style={{ maxWidth: '500px' }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="May 2016"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faSun} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Redid and Expanded Outdoor Yards
          </h3>
          <p className="!mb-4">
            We upgraded to 7ft fencing as well as expanded the play yards to
            create more space for the dogs to run and play!
          </p>
          <GatsbyImage
            image={getImage(data.image12)}
            alt="yard construction"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="July 2015"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faCouch} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Our First Fully Private Suite
          </h3>
          <p className="!mb-4">
            The cottage is a welcoming, stand alone suite on the opposite side
            of the building from the rest of the dogs for those who do better
            with more privacy. It includes its own fireplace and furniture to
            create a cozy, living room feel.
          </p>
          <GatsbyImage
            image={getImage(data.image13)}
            alt="living room"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="June 2015"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faDog} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Our first Open Concept Area
          </h3>
          <p className="!mb-4">
            As a new service we started offering open concept boarding. This new
            service is great for friendly, outgoing dogs who enjoy playing!
          </p>
          <GatsbyImage
            image={getImage(data.image14)}
            alt="large open room"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="May 2015"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faLock} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Added security cameras
          </h3>
          <p className="!mb-4">
            As an additional security feature we added security cameras so we
            are able to check on your pets at any time of the day no matter
            where we are!
          </p>
          <GatsbyImage
            image={getImage(data.image5)}
            alt="security footage"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="July 2014"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faLock} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Added outdoor Roof to all private, outdoor kennel runs.
          </h3>
          <p>
            As an additional security feature we added roofs to all outdoor runs
            to ensure that your pet is safe.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="February 2014"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHome} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Built Our second Kennel
          </h3>
          <p className="!mb-4">
            We decided to take a big leap of faith and convert our garage into a
            brand new kennel building. At this time we also expanded our outdoor
            play yards!
          </p>
          <GatsbyImage
            image={getImage(data.image15)}
            alt="building construction"
            className="rounded-xl"
            style={{ maxWidth: 400 }}
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="April 2009"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon icon={faHome} size="2x" style={{ width: 24 }} />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Built our first kennel
          </h3>
          <p>
            In 2009 we built our first kennel after receiving many requests from
            our grooming clients. Our first kennel was created in one of our
            existing buildings and had 5 indoor/outdoor runs.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          textClassName="!bg-vip-turquoise"
          date="1992"
          iconStyle={{ background: 'white' }}
          icon={
            <FontAwesomeIcon
              icon={faDoorOpen}
              size="2x"
              style={{ width: 24 }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Very Important Pets was Established
          </h3>
          <p>
            Laura first started Very Important Pets as a mobile grooming salon
            in 1992. Soon afterwards she bought a shop in Stettler where she
            continued with her grooming career.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "new_fence2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "tire_playground.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "cat_tree.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "new_fence1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "security.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "dog_bed.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image7: file(relativePath: { eq: "hail.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image8: file(relativePath: { eq: "new_fence2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image9: file(relativePath: { eq: "101.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image10: file(relativePath: { eq: "og.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image11: file(relativePath: { eq: "paw_patrol.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image12: file(relativePath: { eq: "oliver_yard.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image13: file(relativePath: { eq: "cottage2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image14: file(relativePath: { eq: "new_floor.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image15: file(relativePath: { eq: "oliver.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image16: file(relativePath: { eq: "pp_yard_extension.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image17: file(relativePath: { eq: "og.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image18: file(relativePath: { eq: "pet_first_aid.JPG" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image19: file(relativePath: { eq: "potm/elsie.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default FuturePlans;
